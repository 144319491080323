<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">
            {{
              currentRouteName === "evaluating-criteria-create"
                ? "Add Criteria"
                : "Edit Criteria"
            }}
          </h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card" v-if="!loading">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row mb-3">
                <label for="name" class="col-md-3 form-label">
                  Criteria Name
                  <span class="text-danger">*</span>
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'evaluating-criteria-edit',
                      'evaluating-criteria-create',
                    ])
                  "
                >
                  <input
                    type="text"
                    id="name"
                    name="name"
                    class="form-control"
                    placeholder="Criteria Name"
                    v-model="criteria.name"
                    :class="{
                      'p-invalid': v$.criteria.name.$error || errorFor('name'),
                    }"
                  />
                  <v-errors
                    :serverErrors="errorFor('name')"
                    :vuelidateErrors="{
                      errors: v$.criteria.name.$errors,
                      value: 'Criteria Name',
                    }"
                  ></v-errors>
                </div>
                <div
                  class="col-md-6"
                  v-else-if="hasPermissions(['evaluating-criteria-list'])"
                >
                  <input
                    type="text"
                    id="name"
                    name="name"
                    disabled
                    class="form-control"
                    placeholder="Criteria Name"
                    v-model="criteria.name"
                  />
                </div>
              </div>

              <!-- <div class="row mb-3">
                                <label for="level" class="col-md-3 form-label">
                                    Program Name
                                    <span class="text-danger">*</span>
                                </label>
                                <div class="col-md-6">
                                    <input type="radio" class="form-check-input me-1" id="all" name="select_program_name"
                                        value="all" v-model="select_program_name" />
                                    <label for="all" class="me-3">All</label>
                                    <input type="radio" class="form-check-input me-1" id="custom" name="select_program_name"
                                        value="custom" v-model="select_program_name" />
                                    <label for="custom">Custom</label>
                                </div>
                            </div> -->

              <!-- v-if="select_program_name == 'custom'" -->
              <div class="row mb-3">
                <label for="level" class="col-md-3 form-label">
                  Program Name
                  <span class="text-danger">*</span>
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'evaluating-criteria-edit',
                      'evaluating-criteria-create',
                    ])
                  "
                >
                  <div class="d-flex">
                    <div class="d-flex align-items-center me-3">
                      <input
                        type="radio"
                        name="choose"
                        :checked="checkAllState == 'all'"
                        @click="handleRadioClick('all')"
                      />
                      <label>All</label>
                    </div>
                    <div class="d-flex align-items-center">
                      <input
                        type="radio"
                        name="choose"
                        :checked="checkAllState == 'select'"
                        @click="handleRadioClick('select')"
                      />
                      <label>Select</label>
                    </div>
                  </div>
                  <div v-if="showSelectBox">
                    <v-select
                      class="custom-selectCSS"
                      multiple
                      v-model="criteria.programs"
                      label="name"
                      :options="programs"
                      :selectable="(options) => isSelectableProgram(options)"
                      :clearable="false"
                      :class="{
                        'p-invalid':
                          v$.criteria.programs.$error || errorFor('name'),
                      }"
                    >
                    </v-select>
                    <v-errors
                      :serverErrors="errorFor('name')"
                      :vuelidateErrors="{
                        errors: v$.criteria.programs.$errors,
                        value: 'Program Name',
                      }"
                    ></v-errors>
                  </div>
                </div>
                <div
                  class="col-md-6"
                  v-else-if="hasPermissions(['evaluating-criteria-list'])"
                >
                  <div class="d-flex">
                    <div class="d-flex align-items-center me-3">
                      <input
                        type="radio"
                        name="choose"
                        :checked="checkAllState == 'all'"
                        disabled
                      />
                      <label>All</label>
                    </div>
                    <div class="d-flex align-items-center">
                      <input
                        type="radio"
                        name="choose"
                        disabled
                        :checked="checkAllState == 'select'"
                      />
                      <label>Select</label>
                    </div>
                  </div>
                  <div v-if="showSelectBox">
                    <v-select
                      class="custom-selectCSS"
                      multiple
                      v-model="criteria.programs"
                      label="name"
                      disabled
                    >
                    </v-select>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <label for="type" class="col-md-3 form-label">
                  Type
                  <span class="text-danger">*</span>
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'evaluating-criteria-edit',
                      'evaluating-criteria-create',
                    ])
                  "
                >
                  <v-select
                    v-model="criteria.type"
                    label="name"
                    :options="types"
                    :selectable="(options) => isSelectableType(options)"
                    :reduce="(name) => name.name"
                    :clearable="false"
                    :class="{
                      'p-invalid': v$.criteria.type.$error || errorFor('type'),
                    }"
                  >
                  </v-select>
                  <v-errors
                    :serverErrors="errorFor('type')"
                    :vuelidateErrors="{
                      errors: v$.criteria.type.$errors,
                      value: 'Level',
                    }"
                  ></v-errors>
                </div>

                <div
                  class="col-md-6"
                  v-else-if="hasPermissions(['evaluating-criteria-list'])"
                >
                  <v-select
                    v-model="criteria.type"
                    label="name"
                    :clearable="false"
                    :options="types"
                    disabled
                  >
                  </v-select>
                </div>
              </div>
              <div
                v-if="criteria.type == 'In-Class' || criteria.type == 'OJT'"
                class="row mb-3"
              >
                <label for="level" class="col-md-3 form-label">
                  Level
                  <span class="text-danger">*</span>
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'evaluating-criteria-edit',
                      'evaluating-criteria-create',
                    ])
                  "
                >
                  <v-select
                    v-model="criteria.level"
                    label="name"
                    :options="levels"
                    :selectable="(options) => isSelectableLevel(options)"
                    :reduce="(name) => name.name"
                    :clearable="false"
                    :class="{
                      'p-invalid':
                        v$.criteria.level.$error || errorFor('level'),
                    }"
                  >
                  </v-select>
                  <v-errors
                    :serverErrors="errorFor('level')"
                    :vuelidateErrors="{
                      errors: v$.criteria.level.$errors,
                      value: 'Level',
                    }"
                  ></v-errors>
                </div>

                <div
                  class="col-md-6"
                  v-else-if="hasPermissions(['evaluating-criteria-list'])"
                >
                  <v-select v-model="criteria.level" label="name" disabled>
                  </v-select>
                </div>
              </div>

              <div
                class="row mb-3"
                v-if="currentRouteName === 'evaluating-criteria-update'"
              >
                <label for="status" class="col-md-3 form-label"> Status </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'evaluating-criteria-edit',
                      'evaluating-criteria-create',
                    ])
                  "
                >
                  <select name="status" v-model="status" class="form-control">
                    <option :value="1">Active</option>
                    <option :value="0">Inactive</option>
                  </select>
                </div>
                <div
                  class="col-md-6"
                  v-else-if="hasPermissions(['evaluating-criteria-list'])"
                >
                  <select
                    name="status"
                    v-model="status"
                    class="form-control"
                    disabled
                  >
                    <option :value="1">Active</option>
                    <option :value="0">Inactive</option>
                  </select>
                </div>
              </div>

              <div class="row mb-3" v-if="status == 0">
                <label for="remark" class="col-md-3 form-label"> Remark </label>
                <div class="col-md-6">
                  <textarea
                    type="text"
                    id="remark"
                    name="remark"
                    class="form-control"
                    placeholder="Remark"
                    v-model="remark"
                    :class="{
                      'p-invalid': v$.remark.$error || errorFor('remark'),
                    }"
                  >
                  </textarea>
                  <v-errors
                    :serverErrors="errorFor('remark')"
                    :vuelidateErrors="{
                      errors: v$.remark.$errors,
                      value: 'Remark',
                    }"
                  ></v-errors>
                </div>
              </div>
              <!-- end row -->
              <div
                class="row"
                v-if="
                  hasPermissions([
                    'evaluating-criteria-edit',
                    'evaluating-criteria-create',
                  ])
                "
              >
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'evaluating-criteria' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName === 'evaluating-criteria-update'
                          ? updateCriteria()
                          : createCriteria()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import { watch } from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";

//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    vSelect,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      // select_program_name: "",
      programs: [],
      levels: [],
      types: [
        {
          id: 1,
          name: "Orientation",
        },
        {
          id: 2,
          name: "In-Class",
        },
        {
          id: 3,
          name: "OJT",
        },
      ],
      criteria: {
        name: "",
        programs: [],
        type: "",
        level: "",
      },
      status: "1",
      remark: "",
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      loading: false,
      isLoading: false,
      showSelectBox: false,
      checkAllState: "",
    };
  },
  validations() {
    return {
      criteria: {
        name: { required },
        programs: { required },
        type: { required },
        level: {
          required: requiredIf(() => {
            return this.criteria.type != "Orientation";
          }),
        },
      },
      remark: {
        required: requiredIf(() => {
          return this.status != 1;
        }),
      },
    };
  },
  methods: {
    isSelectableProgram(options) {
      if (!this.criteria.programs || !options) return true;
      return (
        this.criteria.programs.find((program) => program.id == options.id) ===
        undefined
      );
      // return this.criteria.programs.
    },
    isSelectableType(options) {
      if (!this.criteria.type || !options) return true;
      return this.criteria.type != options.name;
    },
    isSelectableLevel(options) {
      if (!this.criteria.level || !options) return true;
      return this.criteria.level != options.name;
    },
    getPrograms() {
      axios
        .get(`${this.baseUrlHRIS}api/programs`)
        .then((response) => {
          this.programs = response.data.data.map(function (val) {
            return {
              id: val.id,
              name: val.name,
              short_name: val.short_name,
            };
          });
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
    async getAllLevels() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v1/levels`).then((response) => {
        this.levels = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      });
    },
    async getCriteriaById() {
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v2/evaluating-criteria/${this.$route.params.id}`
        )
        .then((response) => {
          const result = response.data.data;
          this.criteria = result;
          this.criteria.name = result.name;
          this.criteria.programs = result.programs.map(function (val) {
            return {
              id: val.program_id,
              name: val.program_name,
              short_name: val.program_short_name,
            };
          });
          if (this.criteria.programs.length == this.programs.length) {
            this.showSelectBox = false;
            this.checkAllState = "all";
          } else {
            this.checkAllState = "select";
            this.showSelectBox = true;
          }
          this.criteria.type = result.type;
          this.criteria.level = result.level;
          this.status = result.status;
          this.remark = result.remark;
        })
        .catch(() => {
          this.toast.error("Not Found Criteria!");
        });
      this.loading = false;
    },

    async createCriteria() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      // if (this.select_program_name != "custom") {
      //     this.criteria.programs = this.programs;
      //     console.log(this.criteria.programs, "programs");
      // }

      this.isLoading = true;

      await axios
        .post(`${this.baseUrl}admin/v2/evaluating-criteria`, this.criteria)
        .then(() => {
          this.$router.push({ name: "evaluating-criteria" });
          this.toast.success("Successfully Created Criteria!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },

    async updateCriteria() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      let data = {
        name: this.criteria.name,
        programs: this.criteria.programs,
        type: this.criteria.type,
        level: this.criteria.level,
        status: this.status,
        remark: this.remark,
      };
      this.isLoading = true;
      axios
        .put(
          `${this.baseUrl}admin/v2/evaluating-criteria/${this.$route.params.id}`,
          data
        )
        .then(() => {
          this.$router.push({ name: "evaluating-criteria" });
          this.toast.success("Successfully Criteria Updated!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },

    handleRadioClick(value) {
      if (value == "select") {
        this.criteria.programs = [];
        this.showSelectBox = true;
      } else {
        this.showSelectBox = false;
        this.criteria.programs = this.programs;
      }
    },
  },
  //     watch: {
  //   selectChoice(value) {
  //     // Code to run when selectChoice changes
  //     console.log('selectChoice changed:', value);
  //   },
  //   selectAll(value) {
  //     // Code to run when selectAll changes

  //     console.log('selectAll changed:', value);
  //   }
  // },
  created() {
    this.getPrograms();
    this.getAllLevels();
    if (this.currentRouteName == "evaluating-criteria-update") {
      this.getCriteriaById();
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
</style>
